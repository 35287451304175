<template>
    <data-table
        class="table-sm-font"
        :auto-update="false"
        :header="header"
        :loading="loading"
        :data="list"
        :buttons="buttons"
        :lang="currentLang"
        :responsive="'lg'"
        :paging="true"
        @download="onDownload"
        @delete="onDelete"
        @browse="onBrowse"
        @browseTop="onBrowseTop"
    />
</template>

<script>
function fileSizeSI (a, b, c, d, e) {
    // eslint-disable-next-line no-return-assign
    return (b = Math, c = b.log, d = 1e3, e = c(a) / c(d) | 0, a / b.pow(d, e)).toFixed(2) +
 ' ' + (e ? 'kMGTPEZY'[--e] + 'B' : 'Bytes')
}

export default {
    name: 'DirectoryDataTable',
    props: {
        dataset: {
            type: Array,
            required: true
        },
        loading: {
            type: Boolean,
            required: false,
            default: false
        },
        topFolder: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        currentLang () {
            return (this.$store.state.lang === 'cz') ? 'cs_CZ' : 'en_US'
        },
        list () {
            return [
                ...((!this.topFolder) ? [
                    {
                        filename: '..',
                        lastChange: null,
                        size: null,
                        authorEmail: null,
                        version: null,
                        isDirectory: false
                    }
                ] : []),
                ...this.dataset
            ]
        },
        header () {
            return [
                {
                    text: this.$t('directoryDataTable.filename'),
                    data: 'filename',
                    sortable: true,
                    filterable: true
                },
                {
                    text: this.$t('directoryDataTable.lastChange'),
                    data: 'lastChange',
                    sortable: true,
                    filterable: true,
                    format (value) {
                        return ((value) ? (new Date(value)).toLocaleString() : value)
                    }
                },
                {
                    text: this.$t('directoryDataTable.size'),
                    data: 'size',
                    sortable: true,
                    filterable: true,
                    format (value) {
                        return (value) ? fileSizeSI(value) : ''
                    }
                },
                {
                    text: this.$t('directoryDataTable.authorEmail'),
                    data: 'authorEmail',
                    sortable: true,
                    filterable: true
                },
                {
                    text: this.$t('directoryDataTable.version'),
                    data: 'version',
                    sortable: true,
                    filterable: true
                }
            ]
        },
        buttons () {
            return [
                {
                    event: 'download',
                    variant: 'primary',
                    text: this.$t('directoryDataTable.download'),
                    visibleIf: (row) => {
                        return !row.row.isDirectory
                    }
                },
                {
                    event: 'delete',
                    variant: 'danger',
                    text: this.$t('directoryDataTable.delete'),
                    confirm: true,
                    confirmText: this.$t('directoryDataTable.confirmMessage'),
                    visibleIf: (row) => {
                        return this.$user.email === row.row.authorEmail || this.$user.isInRole('admin')
                    }
                },
                {
                    event: 'browse',
                    variant: 'primary',
                    text: this.$t('directoryDataTable.browse'),
                    visibleIf: (row) => {
                        return row.row.isDirectory && row.row.filename !== '..'
                    }
                },
                {
                    event: 'browseTop',
                    variant: 'primary',
                    text: this.$t('directoryDataTable.browseTop'),
                    visibleIf: (row) => {
                        return row.row.filename === '..'
                    }
                }
            ]
        }
    },
    methods: {
        onDownload (row) {
            this.$emit('download', row)
        },
        onDelete (row) {
            this.$emit('delete', row)
        },
        onBrowse (row) {
            this.$emit('browse', row)
        },
        onBrowseTop () {
            this.$emit('browseTop')
        }
    }
}
</script>

<i18n>
{
    "cz": {
        "directoryDataTable": {
            "filename": "Název souboru",
            "authorEmail": "Autor",
            "size": "Velikost",
            "lastChange": "Změna",
            "version": "Verze",
            "download": "Stáhnout",
            "delete": "Smazat",
            "browse": "Procházet",
            "browseTop": "Zpět",
            "confirmMessage": "Opravdu smazat?"
        }
    },
    "en": {

    }
}
</i18n>
