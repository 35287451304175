<template>
    <div>
        <div class="container">
            <h1>{{$t('adminStorage.heading')}}</h1>
            <p>{{$t('adminStorage.subtitle')}}</p>
        </div>
        <div class="container-fluid" v-if="brokerId !== null">
            <div class="row mb-4" v-if="showUploader">
                <div class="col-12 col-md-6">
                    <div class="p-4 bg-white shadow rounded-sm">
                        <h5>{{$t('adminStorage.uploaderLabel')}}</h5>
                        <uploader-box
                            :uploader-name="'brokerUpload'"
                            :multi="true"
                            :url="uploadUrl"
                            @uploaded="onUploaded"
                        />
                        <div class="mt-4">
                            <b-btn
                                variant="light"
                                size="sm"
                                @click.prevent="showUploader = false"
                            >
                                <span class="iconify" data-icon="mdi:close"></span> {{$t('adminStorage.close')}}
                            </b-btn>
                        </div>
                    </div>
                </div>
            </div>

            <div class="p-4 bg-white shadow rounded-sm">
                <h3>Soubory zpřostředkovatele {{brokerName}}</h3>
                <div class="mb-4">
                    <b-btn
                        variant="light"
                        size="sm"
                        :to="{ name: 'AdminStorage', params: {} }"
                        class="mr-4"
                    >
                        <span class="iconify" data-icon="mdi:close"></span> Zavřít
                    </b-btn>
                    <b-btn
                        variant="primary"
                        size="sm"
                        @click.prevent="showUploader = true"
                        class="mr-2"
                    >
                        <span class="iconify" data-icon="mdi:upload"></span> {{$t('adminStorage.openUploader')}}
                    </b-btn>
                </div>
                <directory-data-table
                    :dataset="files"
                    :loading="filesLoading"
                    :top-folder="topFolder"
                    @download="onDownload"
                    @delete="onDelete"
                />
            </div>
        </div>
        <div class="container" v-else>
            <brokers-data-table
                :loading="brokersLoading"
                :dataset="izdocsBrokersEnum"
            />
        </div>
    </div>
</template>

<script>
import UploaderBox from './Components/UploaderBox.vue'
import DirectoryDataTable from './Components/DirectoryDataTable.vue'
import BrokersDataTable from './Components/BrokersDataTable.vue'

export default {
    name: 'Storage',
    components: {
        UploaderBox,
        DirectoryDataTable,
        BrokersDataTable
    },
    props: {
        brokerId: {
            required: false,
            default: null
        }
    },
    data () {
        return {
            files: [],
            filesLoading: true,
            topFolder: true,
            showUploader: false,
            izdocsBrokersEnum: [],
            brokersLoading: true
        }
    },
    computed: {
        uploadUrl () {
            return this.$api.fileStorage.getBrokerUploadUrl(this.brokerId)
        },
        brokerName () {
            return (this.brokerId && this.izdocsBrokersEnum.length > 0) ? this.izdocsBrokersEnum.filter(item => `${item.value}` === `${this.brokerId}`)[0].text : null
        }
    },
    watch: {
        brokerId (newValue) {
            if (!newValue) {
                this.files = []
                this.filesLoading = false
                this.showUploader = false
            } else {
                this.$nextTick(() => {
                    this.loadBrokers()
                    this.loadFiles()
                })
            }
        }
    },
    mounted () {
        this.loadBrokers()
        this.loadFiles()
    },
    methods: {
        loadBrokers () {
            this.brokersLoading = true
            this.$api.izdocs.getBrokersEnum()
                .then((response) => {
                    this.izdocsBrokersEnum = [...response.data]
                }).catch((error) => {
                    console.error(error)
                    this.$notify.error('Nepodařilo se načíst seznam IZ.')
                }).finally(() => {
                    this.$nextTick(() => {
                        this.brokersLoading = false
                    })
                })
        },
        onUploaded () {
            this.$notify.success(this.$t('adminStorage.fileUploaded'))
            this.loadFiles()
        },
        onDelete (row) {
            if (!this.brokerId) {
                return
            }
            this.$api.fileStorage.deleteBrokerFile(this.brokerId, row.prefix + '/' + row.filename)
                .then(() => {
                    this.$notify.success(this.$t('adminStorage.deleteSuccess'))
                }).catch((error) => {
                    console.error(error)
                    this.$notify.error(this.$t('adminStorage.deleteError'))
                }).finally(() => {
                    this.loadFiles()
                })
        },
        onDownload (row) {
            if (!this.brokerId) {
                return
            }
            this.$api.fileStorage.downloadBrokerFile(this.brokerId, row.prefix + '/' + row.filename)
                .catch((error) => {
                    console.error(error)
                    this.$notify.error(this.$t('adminStorage.downloadError'))
                })
        },
        loadFiles () {
            if (!this.brokerId) {
                return
            }
            this.filesLoading = true
            this.$api.fileStorage.getBrokerFiles(this.brokerId)
                .then((response) => {
                    this.files = [...response.data]
                }).catch((error) => {
                    console.error(error)
                    this.$notify.error('adminStorage.filesLoadError')
                }).finally(() => {
                    this.$nextTick(() => {
                        this.filesLoading = false
                    })
                })
        }
    }
}
</script>

<i18n>
{
    "cz": {
        "adminStorage": {
            "uploaderLabel": "Nahrajte požadované soubory",
            "heading": "Soubory ke stažení",
            "subtitle": "Zde naleznete různorodé soubory ke stažení, případně můžete nahrát vaše soubory, které nám chcete předat.",
            "close": "Zavřít",
            "openUploader": "Nahrát soubory..",
            "downloadError": "Nepodařilo se stáhnout soubor",
            "deleteError": "Nepodařilo se smazat soubor.",
            "deleteSuccess": "Soubor byl smazán.",
            "fileUploaded": "Soubor byl nahrán."
        }
    }
}
</i18n>
