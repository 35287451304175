<template>
    <div class="p-4 bg-white shadow rounded-sm">
        <data-table
            class="table-sm-font"
            :auto-update="false"
            :header="header"
            :loading="loading"
            :data="dataset"
            :buttons="buttons"
            lang="cs_CZ"
            :paging="true"
            @browse="onBrowse"
        />
    </div>
</template>

<script>
export default {
    name: 'BrokersDataTable',
    props: {
        dataset: {
            type: Array,
            required: false,
            default: () => []
        },
        loading: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        header () {
            return [
                {
                    text: 'Investiční zprostředkovatel',
                    data: 'text',
                    sortable: true,
                    filterable: true
                }
            ]
        },
        buttons () {
            return [
                {
                    event: 'browse',
                    variant: 'primary',
                    text: 'Otevřít'
                }
            ]
        }
    },
    methods: {
        onBrowse (row) {
            this.$router.push({ name: 'AdminStorage', params: { brokerId: row.value } }).catch(() => {})
        }
    }
}
</script>
