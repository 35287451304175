<template>
    <div class="__uploader-box">
        <file-pond
            :name="uploaderName"
            ref="filePondReference"
            :label-idle="uploaderLabel"
            :allow-multiple="multi"
            :accepted-file-types="mimeTypes"
            :server="url"
            :allow-drop="true"
            :labelInvalidField="$t('labelInvalidField')"
            :labelFileWaitingForSize="$t('labelFileWaitingForSize')"
            :labelFileSizeNotAvailable="$t('labelFileSizeNotAvailable')"
            :labelFileLoading="$t('labelFileLoading')"
            :labelFileLoadError="$t('labelFileLoadError')"
            :labelFileProcessing="$t('labelFileProcessing')"
            :labelFileProcessingComplete="$t('labelFileProcessingComplete')"
            :labelFileProcessingAborted="$t('labelFileProcessingAborted')"
            :labelFileProcessingError="$t('labelFileProcessingError')"
            :labelFileProcessingRevertError="$t('labelFileProcessingRevertError')"
            :labelFileRemoveError="$t('labelFileRemoveError')"
            :labelTapToCancel="$t('labelTapToCancel')"
            :labelTapToRetry="$t('labelTapToRetry')"
            :labelTapToUndo="$t('labelTapToUndo')"
            :labelButtonRemoveItem="$t('labelButtonRemoveItem')"
            :labelButtonAbortItemLoad="$t('labelButtonAbortItemLoad')"
            :labelButtonRetryItemLoad="$t('labelButtonRetryItemLoad')"
            :labelButtonAbortItemProcessing="$t('labelButtonAbortItemProcessing')"
            :labelButtonUndoItemProcessing="$t('labelButtonUndoItemProcessing')"
            :labelButtonRetryItemProcessing="$t('labelButtonRetryItemProcessing')"
            :labelButtonProcessItem="$t('labelButtonProcessItem')"
            :labelFileTypeNotAllowed="$t('labelFileTypeNotAllowed')"
            :fileValidateTypeLabelExpectedTypes="`${$t('fileValidateTypeLabelExpectedTypes')} {allTypes}`"
            :labelMaxFileSizeExceeded="$t('labelMaxFileSizeExceeded')"
            :labelMaxFileSize="`${$t('labelMaxFileSize')} {filesize}`"
            :labelMaxTotalFileSizeExceeded="$t('labelMaxTotalFileSizeExceeded')"
            :labelMaxTotalFileSize="`${$t('labelMaxTotalFileSize')} {filesize}`"
            maxFileSize="30MB"
            instantUpload="true"
            @processfile="onUploaderProcessFile"
            @removefile="onUploaderRemoveFile"
        />
    </div>
</template>

<style lang="scss">
body {
    .filepond--root .filepond--drop-label {
        // min-height: 100%;
        cursor: pointer;
    }

    .filepond--panel-root {
        border-radius: 2px;
    }
}

</style>

<script>
import 'filepond/dist/filepond.css'
import vueFilePondFactory from 'vue-filepond'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size'

const FilePond = vueFilePondFactory(FilePondPluginFileValidateType, FilePondPluginFileValidateSize)

export default {
    name: 'UploaderBox',
    components: {
        FilePond
    },
    props: {
        uploaderName: {
            type: String,
            required: false,
            default: 'uploader'
        },
        label: {
            type: String,
            required: false
        },
        url: {
            type: String,
            required: true
        },
        multi: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data () {
        return {}
    },
    computed: {
        mimeTypes () {
            return [
                'image/jpeg',
                'image/png',
                'image/tiff',
                'application/zip',
                'application/x-zip-compressed',
                'application/pdf',
                'application/msword',
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                'application/vnd.oasis.opendocument.text',
                'application/vnd.oasis.opendocument.spreadsheet',
                'application/vnd.ms-excel',
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            ].join(', ')
        },
        uploaderLabel () {
            if (this.label) {
                return this.label
            }
            return `<strong>${this.$t('labelText')}<span class="filepond--label-action">${this.$t('labelTextLink')}</span></strong>`
        }
    },
    methods: {
        onUploaderProcessFile (error, data) {
            if (error) {
                console.error(error)
            } else {
                this.$emit('uploaded', data)
            }
        },
        onUploaderRemoveFile (error, data) {
            if (error) {
                console.error(error)
            } else {
                this.$emit('removed', data)
            }
        }
    }
}
</script>

<i18n>
{
    "cz": {
        "labelInvalidField": "Pole obsahuje neplatné soubory",
        "labelFileWaitingForSize": "Získávání informací o velikosti",
        "labelFileSizeNotAvailable": "Velikost není dostupná",
        "labelFileLoading": "Načítání",
        "labelFileLoadError": "Chyba během načítání",
        "labelFileProcessing": "Nahrávám",
        "labelFileProcessingComplete": "Nahrávání dokončeno",
        "labelFileProcessingAborted": "Nahrávání zrušeno",
        "labelFileProcessingError": "Chyba během nahrávání",
        "labelFileProcessingRevertError": "Chyba při rušení",
        "labelFileRemoveError": "Chyba při smazání",
        "labelTapToCancel": "stiskněte pro zrušení",
        "labelTapToRetry": "stiskněte pro opakování",
        "labelTapToUndo": "stiskněte pro vrácení zpět",
        "labelButtonRemoveItem": "Smazat",
        "labelButtonAbortItemLoad": "Zrušit",
        "labelButtonRetryItemLoad": "Opakovat",
        "labelButtonAbortItemProcessing": "Zrušit",
        "labelButtonUndoItemProcessing": "Zpět",
        "labelButtonRetryItemProcessing": "Opakovat",
        "labelButtonProcessItem": "Nahrát",
        "labelFileTypeNotAllowed": "Nepodporovaný formát souboru",
        "fileValidateTypeLabelExpectedTypes": "Lze zvolit pouze: ",
        "labelMaxFileSizeExceeded": "Soubor je příliš velký.",
        "labelMaxFileSize": "Maximální velikost je ",
        "labelMaxTotalFileSizeExceeded": "Maximální celková velikost byla překročena",
        "labelMaxTotalFileSize": "Maximální celková velikost je ",
        "labelText": "Přetáhněte soubor k nahrání sem, nebo ",
        "labelTextLink": "klikněte zde.."
    },
    "en": {
        "labelInvalidField": "Field contains invalid files",
        "labelFileWaitingForSize": "Get Size Information",
        "labelFileSizeNotAvailable": "Size Not Available",
        "labelFileLoading": "Loading",
        "labelFileLoadError": "Error loading",
        "labelFileProcessing": "Loading",
        "labelFileProcessingComplete": "Upload Complete",
        "labelFileProcessingAborted": "Upload aborted",
        "labelFileProcessingError": "Error loading",
        "labelFileProcessingRevertError": "Interrupt Error",
        "labelFileRemoveError": "Error deleting",
        "labelTapToCancel": "press to cancel",
        "labelTapToRetry": "press to repeat",
        "labelTapToUndo": "press to undo",
        "labelButtonRemoveItem": "Delete",
        "labelButtonAbortItemLoad": "Cancel",
        "labelButtonRetryItemLoad": "Repeat",
        "labelButtonAbortItemProcessing": "Cancel",
        "labelButtonUndoItemProcessing": "Back",
        "labelButtonRetryItemProcessing": "Repeat",
        "labelButtonProcessItem": "Upload",
        "labelFileTypeNotAllowed": "Unsupported file format",
        "fileValidateTypeLabelExpectedTypes": "Only:",
        "labelMaxFileSizeExceeded": "The file is too large.",
        "labelMaxFileSize": "Maximum size is",
        "labelMaxTotalFileSizeExceeded": "Maximum total size exceeded",
        "labelMaxTotalFileSize": "Maximum total size is",
        "labelText": "Drag the file to upload here, or",
        "labelTextLink": "click here .."
    },
    "hr": {
        "labelInvalidField": "Polje sadrži nevaljane datoteke",
        "labelFileWaitingForSize": "Dohvati podatke o veličini",
        "labelFileSizeNotAvailable": "Veličina nije dostupna",
        "labelFileLoading": "Učitavanje",
        "labelFileLoadError": "Pogreška pri učitavanju",
        "labelFileProcessing": "Učitavanje",
        "labelFileProcessingComplete": "Prijenos završen",
        "labelFileProcessingAborted": "Prijenos prekinut",
        "labelFileProcessingError": "Pogreška pri učitavanju",
        "labelFileProcessingRevertError": "Pogreška prekida",
        "labelFileRemoveError": "Pogreška pri brisanju",
        "labelTapToCancel": "pritisnite da biste odustali",
        "labelTapToRetry": "pritisnite za ponavljanje",
        "labelTapToUndo": "pritisnite da biste poništili",
        "labelButtonRemoveItem": "Izbriši",
        "labelButtonAbortItemLoad": "Otkaži",
        "labelButtonRetryItemLoad": "Ponovi",
        "labelButtonAbortItemProcessing": "Otkaži",
        "labelButtonUndoItemProcessing": "Natrag",
        "labelButtonRetryItemProcessing": "Ponovi",
        "labelButtonProcessItem": "Prenesi",
        "labelFileTypeNotAllowed": "Nepodržani format datoteke",
        "fileValidateTypeLabelExpectedTypes": "Samo:",
        "labelMaxFileSizeExceeded": "Datoteka je prevelika.",
        "labelMaxFileSize": "Maksimalna veličina je",
        "labelMaxTotalFileSizeExceeded": "Premašena je maksimalna ukupna veličina",
        "labelMaxTotalFileSize": "Maksimalna ukupna veličina je",
        "labelText": "Povucite datoteku za prijenos ovdje ili",
        "labelTextLink": "kliknite ovdje .."
    }
}
</i18n>
